import React from "react";
import { BG3 } from "../../../assets";
import "./container.css";

const EventCTA = () => {
  return (
    <div
      style={{
        background: `linear-gradient(#00000083, #00000083), url('${BG3}')`,
      }}
      className="container eventcta"
    >
      <h1>Bring the Story to Life – Book an Event</h1>
      <div className="actions">
        <a
          // onClick={() =>
          //   document
          //     .querySelector(".container.eventevents")
          //     .scrollIntoView({ behavior: "smooth" })
          // }
        >
          Calendar
        </a>
        <a href="/topics">Presentation Topics</a>
      </div>
    </div>
  );
};

export default EventCTA;
