import React from "react";
import { Banner } from "../../../assets";
import { useAuth } from "../../../hooks";
import $ from "jquery";
import "./container.css";

const Sidebar = ({ isSidebarVisible, setIsSidebarVisible }) => {
  const loc = window.location.pathname;
  const auth = useAuth();

  React.useEffect(() => {
    $(".menu > ul > li").click(function (e) {
      $(this).find("ul").slideToggle();
      $(this).siblings().find("ul").slideUp();
      $(this).siblings().find("ul").find("li").removeClass("active");
    });

    return () => {
      $(".menu > ul > li").off("click");
    };
  }, []);

  return (
    <div className={`container sidebar ${!isSidebarVisible ? "hidden" : ""}`}>
      <div className="sidebar">
        <div className="head">
          <div className="wrapper">
            <a href="/">
              <img src={Banner} alt="" />
            </a>
            <button
              onClick={() => setIsSidebarVisible(!isSidebarVisible)}
              className="nav-menu"
            >
              <span className="material-symbols-outlined">menu</span>
            </button>
          </div>
        </div>
        <div className="nav">
          <div className="menu">
            <p className="title">Main</p>
            <ul>
              <li>
                <a
                  className={loc === "/dashboard" ? "active" : ""}
                  href="/dashboard"
                >
                  <span
                    style={{ color: "#399986" }}
                    className="icon material-symbols-outlined"
                  >
                    dashboard
                  </span>
                  <span className="text">Dashboard</span>
                </a>
              </li>

              <li>
                <a href="#">
                  <span
                    style={{ color: "#399986" }}
                    className="icon material-symbols-outlined"
                  >
                    scoreboard
                  </span>
                  <span className="text">Score Match</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="menu">
            <p className="title">Moderator</p>
            <ul>
              <li>
                <a href="#">
                  <span className="icon material-symbols-outlined">add</span>
                  <span className="text">Create Match</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="menu">
            <p className="title">Administrator</p>
            <ul>
              <li>
                <a href="#">
                  <span className="icon material-symbols-outlined">list</span>
                  <span className="text">Matches</span>
                  <span className="arrow material-symbols-outlined">
                    keyboard_arrow_down
                  </span>
                </a>
                <ul className="sub-menu">
                  <li>
                    <a href="#">
                      <span className="text">Active</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="text">Upcoming</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="text">Previous</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">
                  <span className="icon material-symbols-outlined">event</span>
                  <span className="text">Events</span>
                  <span className="arrow material-symbols-outlined">
                    keyboard_arrow_down
                  </span>
                </a>
                <ul className="sub-menu">
                  <li>
                    <a href="#">
                      <span className="text">Active</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="text">Upcoming</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="text">Previous</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">
                  <span
                    style={{ color: "goldenrod" }}
                    className="icon material-symbols-outlined"
                  >
                    key
                  </span>
                  <span className="text">Security</span>
                  <span className="arrow material-symbols-outlined">
                    keyboard_arrow_down
                  </span>
                </a>
                <ul className="sub-menu">
                  <li>
                    <a href="#">
                      <span className="text">Users</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="text">Events</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="text">Alerts</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">
                  <span className="icon material-symbols-outlined">
                    monitoring
                  </span>
                  <span className="text">Analytics</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="menu">
            <div className="title">Owner</div>
            <ul>
              <li>
                <a href="#">
                  <span className="icon material-symbols-outlined">
                    payments
                  </span>
                  <span className="text">Billing</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="icon material-symbols-outlined">help</span>
                  <span className="text">FAQ</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="menu">
          <p className="title">Account</p>
          <ul>
            <li>
              <a onClick={() => auth.logout(null, true, null)} href="#">
                <span className="icon material-symbols-outlined">logout</span>
                <span className="text">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
