import React from "react";
import { Helmet } from "react-helmet";
import { Navbar, AbtHero, Footer, Credit, InfoBar, Divide, AboutMedia } from "../../../containers";
import "./view.css";
import { AboutRec } from "../../../containers/About";

const About = () => {
  return (
    <div className="view about">
      <Helmet>
        <title>About</title>
      </Helmet>
      <div className="container">
        <Navbar />
        <div className="content">
          <AbtHero />
          <Divide  />
          <AboutMedia />
          <AboutRec />
          <Divide spacing />
          <InfoBar version={2} />
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default About;
