import React from "react";
import { Cover } from "../../../assets";
import "./container.css";

const OrderHero = () => {
  return (
    <div className="container orderhero">
      <div className="left">
        <img
          src={Cover}
          alt="The cover photo of Love Letter From Pig: My Brother's Story of Freedom Summer by Julie Kabat"
        />
      </div>
      <div className="right">
        <h1>Order Your Copy</h1>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://www.upress.state.ms.us/Books/L/Love-Letter-from-Pig "
            >
              University Press of Mississippi
            </a>
          </li>
          <li>
            Bookstore.org where you can choose your favorite independent
            bookstore to receive profits:{" "}
            <a
              target="_blank"
              href="https://bookshop.org/p/books/love-letter-from-pig-my-brother-s-story-of-freedom-summer-julie-kabat/20057401?ean=9781496847232"
            >
              Bookstore.org
            </a>
          </li>
          <li className="has-sub">
            Order from your local independent bookstore.
            <ul className="sub-ul">
              {" "}
              {/* Updated this line */}
              <li>
                A few I recommend:
                <ul className="sub-li">
                  <li>
                    <a
                      target="_blank"
                      href="https://chathambookstore.indielite.org/"
                    >
                      The Chatham Bookstore
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.bhny.com/">
                      The Book House of Stuyvesant Plaza
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.pegasusbookstore.com/solano-avenue"
                    >
                      Pegasus on Solano
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.amazon.com/Love-Letter-Pig-Brothers-Freedom/dp/1496847237/ref=cm_cr_arp_d_pdt_img_top?ie=UTF8"
            >
              Amazon
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OrderHero;
