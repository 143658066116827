import React from 'react'
import { useLightbox } from '../../../hooks'
import { Cover2 } from '../../../assets';
import "./container.css";

const OrderFirst = () => {
    const lightbox = useLightbox();

  return (
    <div className="container orderfirst">
      <div className="cover">
        <img
          src={Cover2}
          alt="The audiobook cover of Love Letter from Pig: My Brother's Story of Freedom Summer, by Julie Kabat"
          onClick={() =>
            lightbox.create(
              Cover2,
              "The audiobook cover of Love Letter from Pig: My Brother's Story of Freedom Summer, by Julie Kabat",
              null,
              null
            )
          }
        />
      </div>
      <div className="right">
        <h1>Hardcover and Audiobook Available</h1>
        <img
          src={Cover2}
          alt="The audiobook cover of Love Letter from Pig: My Brother's Story of Freedom Summer, by Julie Kabat"
          onClick={() =>
            lightbox.create(
              Cover2,
              "The audiobook cover of Love Letter from Pig: My Brother's Story of Freedom Summer, by Julie Kabat",
              null,
              null
            )
          }
        />
        <p>
          <em>Lover Letter From Pig: My Brother's Story of Freedom Summer</em> is published by University Press of Mississippi. Released in August 2023, it is 272 pages long, and has 36 black and white illustrations.
          <br />
          <br />
          Author Julie Kabat narrates the audiobook, released in May 2024. It runs 7 hours and 54 minutes.
        </p>
      </div>
    </div>
  )
}

export default OrderFirst
