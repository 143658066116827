import React from "react";
import { Banner } from "../../../assets";
import { useNotifications } from "../../../hooks";
import { Helmet } from "react-helmet";
import "./container.css";

const Footer = ({ expanded = false }) => {
  const loc = window.location.pathname;
  const noti = useNotifications();

  return (
    <div className="container footer">
      <Helmet>
        <link
          href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <div className="top">
        <div>
          <img src={Banner} alt="" />
        </div>
        <div className="links">
          <a className={loc === "/" || loc === "/home" ? "active" : ""} href="/">Home</a>
          <a className={loc === "/about" ? "active" : ""} href="/about">About</a>
          <a className={loc === "/reviews" ? "active" : ""} href="/reviews">Reviews</a>
          <a className={loc === "/events" ? "active" : ""} href="/events">Events</a>
          <a className={loc === "/contact" ? "active" : ""}  href="/contact">Contact</a>
        </div>
        <div>
          <div className="link">
            <a target="_blank" href="https://www.youtube.com/@JulieKabat1964">
              <i className="bx bxl-youtube icon" />
            </a>
            <span>Youtube</span>
          </div>
        </div>
      </div>
      <div className="divide"></div>
      <div className="alt">
        <p>&copy; {new Date().getFullYear()} Julie Kabat. All rights reserved.</p>
        <div className="left">
            <a onClick={(e) => noti.usePreset(e, "construction")} href="/privacy">Privacy Policy</a>
            <a onClick={(e) => noti.usePreset(e, "construction")} href="/cookies">Cookie Policy</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
