import React from "react";
import { Helmet } from "react-helmet";
import {
  Credit,
  EventCTA,
  Footer,
  Navbar,
  EventAbt,
  Divide,
  InfoBar,
  EventEvents,
} from "../../../containers";
import "./view.css";

const Events = () => {

  React.useEffect(() => {
    const urlparams = new URLSearchParams(window.location.search);

    const redir = urlparams.get("redir");

    if(redir && redir === "cal") {
      document.querySelector(".container.eventevents").scrollIntoView({ behavior: "smooth" });
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [])

  return (
    <div className="view events">
      <Helmet>
        <title>Events</title>
      </Helmet>
      <div className="container">
        <Navbar />
        <div className="content">
          <EventCTA />
          <EventAbt />
          <InfoBar version={3} expand />
          <Divide />
          {/* <EventEvents /> */}
          {/* <Divide /> */}
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default Events;
