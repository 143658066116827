import React from "react";
import "./component.css"

const PulseArrow = ({ scrollTo }) => {
    const [hasScrolled, setHasScrolled] = React.useState(false);

    function handleClick() {
        document.querySelector(`${scrollTo}`).scrollIntoView({ behavior: "smooth" });
        setHasScrolled(true);
    }

  return (
    <div onClick={handleClick} className={`component pulsearrow ${hasScrolled ? "hidden" : ""}`}>
      <span className="material-symbols-outlined">keyboard_arrow_down</span>
    </div>
  );
};

export default PulseArrow;
