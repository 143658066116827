import React from "react";
import { Helmet } from "react-helmet";
import {  Credit, Divide, Footer, MainReviews, Navbar } from "../../../containers";
import "./view.css";

const ReviewsPage = () => {
  return (
    <div className="view reviewspage">
      <Helmet>
        <title>Reviews</title>
      </Helmet>
      <div className="container">
        <Navbar />
        <div className="content">
          <MainReviews />
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default ReviewsPage;
