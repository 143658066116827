import {
  Home,
  Login,
  About,
  Contact,
  Order,
  ReviewsPage,
  Events,
  Topics,
  Dashboard,
} from "./views";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import {
  AuthProvider,
  LightboxProvider,
  NotificationProvider,
} from "./providers";
import { NavUp } from "./components";

function App() {
  return (
    <section className="App">
      <NotificationProvider>
        <LightboxProvider>
          <AuthProvider>
            <NavUp />
            <BrowserRouter>
              <Routes>
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/reviews" element={<ReviewsPage />} />
                <Route path="/events" element={<Events />} />
                <Route path="/topics" element={<Topics />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/order" element={<Order />} />
                <Route path="/login" element={<Login />} />

                {/* Auth Routes */}
                <Route path="/dashboard" element={<Dashboard />} />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </LightboxProvider>
      </NotificationProvider>
    </section>
  );
}

export default App;
