import React from "react";
import Cookies from "js-cookie";
import { axios } from "../../utils";
import { AuthContext } from "../../contexts";
import { useNotifications } from "../../hooks";

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const noti = useNotifications();
  const production = false;

  function setTokens(a, r) {
    setIsAuthenticated(true);
    if (a) {
      Cookies.set("JULIE_ACCESS", a, {
        expires: 96,
        secure: production,
        sameSite: "strict",
        path: "/",
      });
    }

    if (r) {
      Cookies.set("JULIE_REFRESH", r, {
        expires: 96,
        secure: production,
        sameSite: "strict",
        path: "/",
      });
    }
  }

  async function login(u, p, c) {
    const ip = await getLoginIP();

    if (!ip) {
      return { success: false, errCode: 1, message: "Unable to retrieve IP." };
    }

    try {
      const response = await axios.post(
        "/login",
        {
          username: u,
          password: p,
          code: c,
          ip: ip,
        },
      );


      const { accessToken, refreshToken, success } = response.data;

      if(!accessToken || !refreshToken) {
        return {
            success: false,
            errCode: 3,
            message: "Invalid Credentials.",
          };
      } else {
        await setTokens(accessToken, refreshToken);
        return {
            success: true,
            message: "Logged in successfully.",
            u: u,
        }
      }

    } catch (error) {
      if (!error?.response) {
        return {
          success: false,
          errCode: 2,
          message: "Unable to reach the server.",
        };
      } else if(error?.response?.data?.message == "Invalid Credentials") {
        return {
          success: false,
          errCode: 3,
          message: "Invalid Credentials.",
        };
      } else {
        return {
          success: false,
          errCode: 4,
          message: "An error occurred.",
        };
      }
    }
  }

  async function getLoginIP() {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
