import React from "react";
import { Helmet } from "react-helmet";
import "./view.css";
import {
  Credit,
  Divide,
  FeatEvents,
  Footer,
  HomeHero,
  InfoBar,
  Navbar,
  Reviews,
  Summary,
} from "../../../containers";
import { PulseArrow } from "../../../components";

const Home = () => {
  return (
    <div className="view home">
      <Helmet>
        <title>Home</title>

        <meta name="title" content="Julie Kabat" />

        {/* Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.juliekabat.com" />
        <meta property="og:title" content="Julie Kabat" />
        <meta
          property="og:image"
          content="https://www.juliekabat.com/Logo.png"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.juliekabat.com" />
        <meta property="twitter:title" content="Julie Kabat" />
        <meta
          property="twitter:image"
          content="https://www.juliekabat.com/Logo.png"
        />
      </Helmet>
      <div className="container">
        <Navbar />
        {/* <PulseArrow scrollTo=".container.summary" /> */}
        <div className="content">
          <HomeHero />
          <Summary />
          <Divide />
          <Reviews />
          <Divide spacing />
          <InfoBar />
          {/* <FeatEvents /> */}
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default Home;
