import React from "react";
import { Family, Lenray, Luke2, Luke3, Vote } from "../../../assets";
import { useLightbox } from "../../../hooks";
import { Divide } from "../../";
import "./container.css";

const MainReviews = () => {
  const lightbox = useLightbox();

  const creviews = [
    {
      text: `Luke did what so many did. He volunteered. Teaching, mobilizing, befriending, giving of himself in ways great and small to Freedom Summer. What is remarkable about that volunteering, is that he wrote it all down so candidly, so copiously, plainly, naively, bravely, commenting, evolving, challenging himself – and challenging each of us who read his incisive thoughts so many years later. Published, his journal entries and letters sit in the middle of a scatter of thousands of words written about Freedom Summer. But, because they were written in a moment of action, written for the purpose of grappling with his conscience, they permit us a rare journey. By writing as he did, he takes us into his mind – and into the hearts of so many others. He does something else, too. Luke was an extraordinary person taking ordinary actions in an extraordinary time. By leaving us with his unvarnished history, he shows us how simple humanity still means something – and how our own inner humanity will always mean something.`,
      attribution:
        "Michael Eastman, Legal Historian | Archivist | Senior Librarian, Stanford University Libraries and Center for Human Rights and International Justice, Stanford University",
    },
    {
      text: `Kabat’s beautiful, moving memoir of her brother Luke’s civil rights activism in Meridian, MS during Freedom Summer is at once a compelling historical document and deeply personal homage. Lucien, a medical student at Stanford, left the safety of Palo Alto in the summer of 1964 to be one of more than 700 student activists risking their lives for racial equality. He’d had a good role model in own his father, blacklisted during the McCarthy era for refusing to fire other blacklisted doctors. Forced to move to Providence, RI for the only work in his field he could get, he and his family were met by the discreet wall of genteel anti-Semitism. It is in the context of this silent shunning that the deep bond between Julie and her brother grew and thrived, and his own activism was born. By using Luke’s letters and diary entries to tell the story, Kabat brings this extraordinary young man into vivid, breathing life.`,
      attribution:
        "Marjorie Darraugh, Manager of Pegasus Books on Solano, Berkeley, California",
    },
    {
      text: `Julie Kabat has written a remarkable book. <em>Love Letter from Pig</em> introduces us to her brother Luke, a third-year medical student teaching in a Mississippi Freedom School. Through Luke we meet his students, thrilled at being exposed to new knowledge; family members, concerned about his welfare; and fellow Freedom Summer volunteers. <em>Love Letter from Pig</em> is about idealism, courage in the face of adversity, but mostly it’s about love.`,
      attribution:
        "Paul Murray, civil rights veteran and professor emeritus at Siena College, New York",
    },
    {
      text: `<em>Love Letter from Pig</em> recounts the complicated story of courageous, non-violent actions against the exploitation of Black Americans in rural Mississippi. While some changes have occurred in Philadelphia and Meridian, Mississippi, the unlearned lessons of that time persist in America. Kabat’s story reveals the tragic outcomes often embedded in the fight for justice and freedom.`,
      attribution:
        "Irving Weissman, Virginia & D.K. Ludwig Professor of Clinical Investigation in Cancer Research and founding director of the immunology program, Stanford University School of Medicine",
    },
    {
      text: `Drawing on a wealth of resources—her brother’s diary and letters, periodicals and books, radio and television, and interviews, some made at the fiftieth anniversary of Freedom Summer in 2014—Julie Kabat creates a compelling story about a significant period in the nation’s history and addresses continuing issues of civil rights and racial equality.`,
      attribution: "Ann J. Abadie, coeditor of The Mississippi Encyclopedia",
    },
    {
      text: `I submit for your approval a list of my own favorites from reading and viewing many of last year’s offerings. <em>Love Letter from Pig: My Brother’s Story of Freedom Summer</em> - In that season of 1964, a coalition of national voter rights organizations flooded Mississippi with volunteers in aid of African Americans long intimidated by local government officials, and/or physically attacked by bigots and brutes (often on the payroll of local police departments). Through it all, including a spot of jail time, Luke Kabat kept his poise and his principles in seeing to it that black men and women, only a handful of generations from slavery, could safely become registered voters. In telling Luke’s story, his sister necessarily credits the admirable Kabat family: Her maternal grandfather Ber-Leib fought in the 1905 anti-czarist Russian revolution; her maternal Uncle Sam, a labor organizer, fought on the anti-fascist side of the Spanish Civil War; her father, a distinguished physician who lost his job in standing up for democratic ideals during the anti-communist hysteria that shamed America in the 1950s. The ideals of the author’s big brother echo the honor of his family elders and speak to his own sense of moral resistance to injustice, and of moral poetry.`,
      attribution: "Thomas Adcock, CulturMag – Berlin, Montag, 1. Januar 2024",
    },
  ];

  const rreviews = [
    {
      text: `This book is absolutely beautiful. A treasure, and so moving... While I read it, I had the conflicting feeling that I couldn't put it down and that I wanted to read it slowly so it would last longer. It stirred up much for me, about my own childhood in the 1960s all the way through to the world we live in now. Read it!`,
      attribution: "Barbara Dobbs MacKenzie",
    },
    {
      text: `The narrative is awakening feelings that I thought were gone forever, and it has lifted my spirits while bringing tears to my eyes as I remember the joys, losses, and hopes of the time.`,
      attribution: "Pat Barbanell, Freedom Summer volunteer",
    },
    {
      text: `Somehow, she’s done it - Julie Kabat has written a page turner mémoire about troubling times in America that combines historical fact with human experience. I couldn’t put it down. Reading it affected me deeply. I’d recommend it to adolescents on up. Younger readers might identify with the young voice of the writer while older readers would identify with the times.`,
      attribution: "Anonymous",
    },
    {
      text: `I thought it was quite moving, well-written, and it does a great job of introducing readers to events most either don't know anything about or have forgotten. She never lets her research overwhelm the story, but the research is thorough and meaningful. …It's wonderful to remember that there are so many people who care. So many people who risked their lives. I, for one, was able to relive tensions and fears and anger; each chapter pushed the story along and provided for reflection as well. The love the Freedom School kids felt for him is palpable, and the letters she includes paint a pretty full portrait of how significant this work was. Plus, it's so much important history. I wish this book could be in every high school library: it seems perfect for those readers as well.`,
      attribution: "Leda Schubert",
    },
    {
      text: `This book is fantastic! I greatly admire the structure, interweaving Luke's personal journey with the racial struggle in Mississippi, the anti-establishment/pro-people commitment of his family, secular Judaism, antisemitism from the Pale to Rhode Island, national politics, her love for Luke, and her own biography––emotional, social, intellectual...all! I've thought of lots of people to recommend it to.`,
      attribution: "Karen Frishkoff",
    },
    {
      text: `This book brings to life a remarkable young man who was dedicated to making America live up to promises made but not kept, especially to African Americans. We come to know the passionate, angry and determined beloved brother who witnesses family and national turmoil as he comes to grips with the imperfections that would spur him on. Those of us still striving for a better world can see our own frustration and disappointment in his story yet we are revived by his determination to make a difference. Such a tragic loss for those who loved him and a grievous loss in the never-ending struggle for racial equality and social justice in our country.`,
      attribution: "Gilda Parella",
    },
    {
      text: `Moving, heartbreaking, powerful. This magnificent story balances the personal with the communal, a close and personal reflection of a moment in American history and the reality of today. While this is a portrait of love, innocence, courage and brilliance, it is also a glimpse of the virus of hate still poisoning our lives.`,
      attribution: "Bianca Gray",
    },
  ];

  return (
    <div className="container mainreviews">
      <h1>REVIEWS</h1>
      <div className="revs">
        {creviews.map((review, index) => {
          let reviewContent = (
            <div className="review" key={index}>
              <p dangerouslySetInnerHTML={{ __html: review.text }}></p>
              <span>— {review.attribution}</span>
            </div>
          );

          if (index === 1) {
            return (
              <>
                <div className="box">
                  <img
                    src={Luke2}
                    alt="Photo of Luke Kabat in the Meridian Community Center Children's Library"
                    onClick={() =>
                      lightbox.create(
                        Luke2,
                        "Photo of Luke Kabat in the Meridian Community Center Children's Library",
                        null,
                        "Photo by Patti Miller, Founder and President of Keeping History Alive Foundation"
                      )
                    }
                  />
                  <div className="text">
                    <span className="title">
                      In the children’s library, Meridian Community Center.
                    </span>
                    <span className="atrib">
                      Photo by Patti Miller, Founder and President of Keeping
                      History Alive Foundation
                    </span>
                  </div>
                </div>
                {reviewContent}
              </>
            );
          } else if (index === 3) {
            return (
              <>
                <div className="box">
                  <img
                    src={Luke3}
                    alt="Photo of the Memorial Service for Michael Schwerner, James Chaney, and Andrew Goodman at burned Mt. Zion Church"
                    onClick={() =>
                      lightbox.create(
                        Luke3,
                        "Memorial Service for Michael Schwerner, James Chaney, and Andrew Goodman at burned Mt. Zion Church (Luke center)",
                        null,
                        "Photo by Mark Levy, Mark Levy Collection, Queens College/CUNY Rosenthal Library Archives"
                      )
                    }
                  />
                  <div className="text">
                    <span className="title">
                      Memorial Service for Michael Schwerner, James Chaney, and
                      Andrew Goodman at burned Mt. Zion Church (Luke center).
                    </span>
                    <span className="atrib">
                      Photo by Mark Levy, Mark Levy Collection, Queens
                      College/CUNY Rosenthal Library Archives
                    </span>
                  </div>
                </div>
                {reviewContent}
              </>
            );
          } else if (index === 5) {
            return (
              <>
                <div className="box">
                  <img
                    className="family"
                    src={Family}
                    alt="Photo of Julie Kabat as a child, with her brothers Lucien (Luke) and David on a rollercoaster"
                    onClick={() =>
                      lightbox.create(
                        Family,
                        "Julie and her brothers, Lucien (Luke) and David, on a rollercoaster",
                        null,
                        "Photo by Herman Kabat"
                      )
                    }
                  />
                  <div className="text">
                    <span className="title">
                      On a rollercoaster, Julie and her brothers, Lucien (Luke)
                      and David.
                    </span>
                    <span className="atrib">Photo by Herman Kabat</span>
                  </div>
                </div>
                <div className="box">
                  <img
                    className="vote"
                    src={Vote}
                    alt="Photo of an Student Non-Violent Coordinating Committee poster announcing 1 man, 1 vote"
                    onClick={() =>
                      lightbox.create(
                        Vote,
                        "Photo of an Student Non-Violent Coordinating Committee poster announcing 1 man, 1 vote",
                        null,
                        "Photo by Danny Lyon."
                      )
                    }
                  />
                  <div className="text">
                    <span className="title">SNCC Poster</span>
                    <span className="atrib">Photo by Danny Lyon.</span>
                  </div>
                </div>
                {reviewContent}
              </>
            );
          }

          return reviewContent;
        })}
      </div>
      <Divide spacing />
      <h2>What Readers are Saying</h2>
      <div className="revs">
        {rreviews.map((review, index) => (
          <div className="review" key={index}>
            <p dangerouslySetInnerHTML={{ __html: review.text }}></p>
            <span>— {review.attribution}</span>
          </div>
        ))}
      </div>
      <Divide spacing />
    </div>
  );
};

export default MainReviews;
