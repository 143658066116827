import React from "react";
import { Julie } from "../../../assets";
import { useLightbox } from "../../../hooks";
import "./container.css";

const AbtHero = () => {
  const lightbox = useLightbox();

  return (
    <div className="container abthero">
      <div className="wrapper">
        <img
          src={Julie}
          alt="Julie Kabat has toured internationally as a composer, performer,
            singer, and storyteller"
          onClick={() =>
            lightbox.create(
              Julie,
              "Julie Kabat has toured internationally as a composer, performer, singer, and storyteller",
              null,
              "Photo by Tanya D’Avanzo"
            )
          }
        />
        <div className="text">
          <h1>About Julie</h1>
          <p>
            Julie Kabat has toured internationally as a composer, performer,
            singer, and storyteller. She has also been recognized as a pioneer
            in the field of arts in education. As a teaching artist, she
            presented many professional development workshops for teachers to
            explore how children can learn to read and write using strategies
            from the performing arts. For over forty years, she worked with
            students in inner city and rural schools, empowering young people to
            discover an authentic writer’s voice while learning how to write
            poems, stories, and plays that they set to music and performed.
          </p>
          <p>
            Julie’s compositions include vocal, choral, theater and chamber
            music. She composed a series of site-specific pieces to celebrate
            the earth and a sense of place. These works were commissioned by the
            Utah New Music Festival in Zion Canyon, Adirondack Park Centennial,
            two schools on the Navajo Reservation and, during a twelve-year
            project, communities up and down the Hudson River. She created and
            toured Child and the Moon-Tree, a one-woman opera for voice and
            computerized synthesizers, inspired by her studies of Noh Theater in
            Kyoto, Japan.
          </p>
          <p>
            Along the way, Julie raised her two daughters as a single mom and
            now visits her five grandchildren who live in Hawaii and the
            Netherlands. She resides with her life partner Wayne Shelton and
            their cat in the Hudson River Valley down the street from the North
            Chatham Free Library where she is a longtime trustee.{" "}
            <span>Love Letter from Pig</span> is her first published book.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AbtHero;
