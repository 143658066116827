import React from "react";
import { LightboxContext } from "../../contexts";
import "./provider.css";

const LightboxProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [imageData, setImageData] = React.useState({
    img: null,
    alt: "",
    title: "",
    description: "",
  });

  function create(img, alt, title, description) {
    setImageData({ img, alt, title, description });
    setIsOpen(true);
  }

  function destroy() {
    setIsOpen(false);
    setImageData({ img: null, alt: "", title: "", description: "" });
  }

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <LightboxContext.Provider value={{ create }}>
      {children}
      {isOpen && (
        <div className="lightbox">
          <span
            onClick={() => destroy()}
            className="material-symbols-outlined icon"
          >
            close
          </span>
          <div className="wrapper">
            <img
              src={imageData.img ? imageData.img : null}
              alt={
                imageData.alt
                  ? imageData.alt
                  : "This image does not have an alternate tag"
              }
            />
          {imageData.title && <h1>{imageData.title}</h1>}
          {imageData.description && <p>{imageData.description}</p>}
          </div>

        </div>
      )}
    </LightboxContext.Provider>
  );
};

export default LightboxProvider;
