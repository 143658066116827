import React from "react";
import { Helmet } from "react-helmet";
import {  Credit, Footer, Navbar, TopicsAccordian } from "../../../containers";
import "./view.css";

const Topics = () => {
  return (
    <div className="view faq">
      <Helmet>
        <title>Topics</title>
      </Helmet>
      <div className="container">
        <Navbar />
        <div className="content">
          <TopicsAccordian />
          <Footer />
          <Credit />
        </div>
      </div>
    </div>
  );
};

export default Topics;
