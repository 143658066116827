import React from "react";
import { Event } from "../../../assets";
import { useLightbox, useNotifications } from "../../../hooks";
import "./container.css";

const EventAbt = () => {
  const lightbox = useLightbox();
  const noti = useNotifications();

  return (
    <div className="container eventabt">
      <div className="left">
        <h2>Big or Small, Julie has an Event for You</h2>
        <div className="img-wrapper">
          <img src={Event} alt="" />
          <div className="text">
            <span className="title">
              Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former
              Freedom School student) at Capital Repertory Theater Company,
              Albany NY
            </span>
          </div>
        </div>
        <p>
          Julie's engaging presentations can include multimedia elements like
          videos and imagery, fostering dynamic conversations around social
          justice, history, and activism. Whether your event draws a diverse
          audience or focuses on specific themes, Julie's talks are tailored to
          inspire, educate, and energize participants of all ages.
        </p>
        <p></p>
        <div className="wrapper">
          <div className="item">
            <h4>Interactive</h4>
            <p>
              Julie gets the audience singing freedom songs. Her presentations
              may be in person or virtual.
            </p>
          </div>
          <div className="item">
            <h4>Close to Home</h4>
            <p>
              Within a 50-mile radius of her home, Julie may be joined by
              Andreesa Coleman, Luke’s Freedom School student.
            </p>
          </div>
        </div>
        <div className="actions">
          <a className="button" href="/contact">
            Reach Out
          </a>
          <a className="arrow" href="/topics">
            Possible Topics
            <span className="material-symbols-outlined">arrow_forward_ios</span>
          </a>
        </div>
      </div>
      <div className="right">
        <img
          onClick={() =>
            lightbox.create(
              Event,
              "Photo of Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former Freedom School student) at Capital Repertory Theater Company, Albany NY",
              "Pre Show Talk",
              "Tamani Wooley, Andreesa Coleman, and Julie Kabat at Capital Repertory Theater Company, Albany NY"
            )
          }
          src={Event}
          alt="Photo of Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former Freedom School student) at Capital Repertory Theater Company, Albany NY"
        />
        <div className="text">
          <span className="title">
            Tamani Wooley, Julie Kabat, and Andreesa Coleman (Luke's former
            Freedom School student) at Capital Repertory Theater Company, Albany
            NY
          </span>
        </div>
      </div>
    </div>
  );
};

export default EventAbt;
