import React from "react";
import "./container.css";

const Summary = () => {
  return (
    <div className="container summary">
      <p>
        In the summer of 1964, the FBI found the smoldering remains of the
        station wagon that James Chaney, Michael Schwerner, and Andrew Goodman
        had been driving before their disappearance. Shortly after this awful
        discovery, Julie Kabat’s beloved brother Luke arrived as a volunteer for
        the Mississippi Summer Project. He was one of more than seven hundred
        volunteers from the North who assisted Black civil rights activists and
        clergy to challenge white supremacy in the nation’s most segregated
        state.
      </p>
      <p>
        During his time in Mississippi, Luke taught biology to Freedom School
        students in Meridian. He helped plan the community memorial service for
        James Chaney, attended the Democratic National Convention to support the
        Mississippi Freedom Democratic Party, and even spent time in jail for
        “contributing to the delinquency of minors.” This arrest followed his
        decision to take students out for ice cream! Through his activism, Luke
        grappled with many issues that continue to haunt and divide us today:
        racialized oppression, threats of violence, and the continuation of de
        facto segregation.
      </p>
      <p>
        Sadly, Luke died just two years after Freedom Summer, leaving behind
        copious letters, diaries, and essays, as well as a lasting impact on his
        younger sister, nicknamed “Pig.” Drawing on a wealth of primary
        resources, especially her brother’s letters and diaries, Kabat delves
        deeply into her family history to understand Luke’s motivations for
        joining the movement and documents his experiences as an activist. In
        addition to Luke’s personal narrative, she includes conversations with
        surviving Freedom School volunteers and students who declare the
        life-long legacy of Freedom Summer. A sister’s tribute to her
        brother,  <span>Love Letter from Pig: My Brother’s Story of Freedom
        Summer</span> addresses ongoing issues of civil rights and racial inequality
        facing the nation today.
      </p>
    </div>
  );
};

export default Summary;
