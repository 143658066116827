import React, { useState, useEffect } from "react";
import "./component.css";
const NavUp = () => {
  const [hidden, setHidden] = useState(true);
  const [direction, setDirection] = useState(1);
  useEffect(() => {
    const handleScroll = () =>
      setHidden(window.scrollY <= window.innerHeight * 0.5);
    window.addEventListener("scroll", handleScroll, { passive: true });
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handleClick = () => {
    window.scrollTo({
      top: direction === 1 ? 0 : document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  const handleChangeDirection = (e) => {
    e.preventDefault();
    setDirection(-direction);
  };
  return (
    <div
      onClick={handleClick}
      className={`component navup ${hidden ? "hidden" : ""} ${
        direction === 1 ? "" : "flip"
      }`}
      onContextMenu={handleChangeDirection}
    >
      <i className="bx bx-chevron-up icon" />
    </div>
  );
};
export default NavUp;
