import React from "react";
import { Helmet } from "react-helmet";
import { Sidebar } from "../../../containers";
import "./view.css";

const Dashboard = () => {
    const [isSidebarVisible, setIsSidebarVisible] = React.useState(true);

  return (
    <div className={`view dashboard ${!isSidebarVisible ? 'hidden' : ''}`}>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
        <div className="container">
            <Sidebar />
            <div className="content">

            </div>
        </div>
    </div>
  );
};

export default Dashboard;
