import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { LoginForm } from "../../../containers";
import "./view.css";

const Login = () => {
  const canvas = React.useRef(null);
  const particleCount = 150;
  const maxDistance = 100;
  const animationFrameId = React.useRef(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const c = canvas.current;
    const ctx = c.getContext("2d");

    c.width = window.innerWidth;
    c.height = window.innerHeight;

    let particles = [];

    class Particle {
      constructor() {
        this.x = Math.random() * c.width;
        this.y = Math.random() * c.height;
        this.vx = (Math.random() - 0.5) * 1;
        this.vy = (Math.random() - 0.5) * 1;
        this.radius = 2;
      }

      update() {
        this.x += this.vx;
        this.y += this.vy;

        if (this.x > c.width) this.x = 0;
        if (this.x < 0) this.x = c.width;
        if (this.y > c.height) this.y = 0;
        if (this.y < 0) this.y = c.height;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
        ctx.fill();
      }
    }

    for (let i = 0; i < particleCount; i++) {
      particles.push(new Particle());
    }

    function animate() {
      ctx.clearRect(0, 0, c.width, c.height);

      particles.forEach((particle) => {
        particle.update();
        particle.draw();
      });

      for (let i = 0; i < particleCount; i++) {
        for (let j = i + 1; j < particleCount; j++) {
          const dx = particles[i].x - particles[j].x;
          const dy = particles[i].y - particles[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < maxDistance) {
            const opacity = 1 - distance / maxDistance;
            ctx.beginPath();
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.strokeStyle = `rgba(255, 255, 255, ${opacity * 0.5})`;
            ctx.lineWidth = 1;
            ctx.stroke();
          }
        }
      }

      animationFrameId.current = requestAnimationFrame(animate);
    }

    animate();

    const handleResize = () => {
      c.width = window.innerWidth;
      c.height = window.innerHeight;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      cancelAnimationFrame(animationFrameId.current);
      window.removeEventListener("resize", handleResize);
    };
  }, [particleCount, maxDistance]);

  return (
    <div className="view login">
      <Helmet>
        <title>Login</title>
        <link
          href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <canvas ref={canvas}></canvas>
      <button onClick={() => window.location.href = '/'}>
        <span className="material-symbols-outlined">arrow_back</span>Home
      </button>
      <LoginForm />
    </div>
  );
};

export default Login;
