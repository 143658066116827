import React from "react";
import { Cover } from "../../../assets";
import { useNotifications } from "../../../hooks";
import "./container.css";

const HomeHero = () => {
  const noti = useNotifications();

  return (
    <div className="container homehero">
      <div className="wrapper">
        <div className="left">
          <img src={Cover} alt="The cover photo of Love Letter From Pig: My Brother's Story of Freedom Summer by Julie Kabat" />
        </div>
        <div className="right">
          <h1>Troubling times – a fractured country.</h1>
          <h2>We’ve been here before.</h2>
          <h4>
            An inspiring, deeply personal story about a tumultuous period in
            civil rights history.
          </h4>
          <h3>
            “I showed them skin under a microscope, and they saw that color is
            less than skin deep and hardly that.” —Luke Kabat in 1964
          </h3>
          <a href="/order">Buy Now</a>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
