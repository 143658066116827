import React from "react";
import { useNotifications } from "../../../hooks";
import "./container.css";

const InfoBar = ({ version = 1, expand = false }) => {
  const [isClosing, setIsClosing] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(true);
  const currentWrapper = React.useRef(null);
  const noti = useNotifications();

  const id = Math.random().toString(36).substr(2, 9);

  function handleClose(id) {
    if (currentWrapper.current) {
      setIsClosing(true);

      setTimeout(() => {
        let height = currentWrapper.current.offsetHeight;
        let width = currentWrapper.current.offsetWidth;
        let duration = 400;
        let intervalDuration = 5;
        let heighDecrementValue = height / (duration / intervalDuration);
        let widthDecrementValue = width / (duration / intervalDuration);

        let interval = setInterval(() => {
          height -= heighDecrementValue;
          width -= widthDecrementValue;
          currentWrapper.current.style.height = `${height}px`;
          currentWrapper.current.style.width = `${width}px`;
          if (height <= 0 || width <= 0) {
            clearInterval(interval);
            currentWrapper.current.style.height = "0px";
            currentWrapper.current.style.width = "0px";
          }
        }, intervalDuration);

        setTimeout(() => {
          currentWrapper.current.parentElement.style.padding = "0px";
          setTimeout(() => {
            setIsVisible(false);
          }, 300);
        }, duration + 150);
      }, 300);
    }
  }

  return (
    <div
      className={`container infobar ${isClosing ? "closing" : ""} ${
        expand ? "expanded" : ""
      }`}
    >
      {version === 1 ? (
        <div id={id} ref={currentWrapper} className="wrapper">
          <div className="left">
            <div className="col">
              <span className="material-symbols-outlined icon">event</span>
            </div>
            <div className="col">
              <h2>Check out My Calendar</h2>
              <p>Stay up to date on all of the events.</p>
            </div>
          </div>
          <div className="right">
            <a
              className="btn"
              href="/events?redir=cal"
            >
              Calendar
            </a>
            <button onClick={() => handleClose()} className="close">
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>
      ) : version === 2 ? (
        <div id={id} ref={currentWrapper} className="wrapper">
          <div className="left">
            <div className="col">
              <span className="material-symbols-outlined icon">edit_note</span>
            </div>
            <div className="col">
              <h2>Get In Contact</h2>
              <p>Have questions? Leave me a message.</p>
            </div>
          </div>
          <div className="right">
            <a className="btn" href="/contact">
              Contact
            </a>
            <button onClick={() => handleClose()} className="close">
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>
      ) : version === 3 ? (
        <div id={id} ref={currentWrapper} className="wrapper">
          <div className="left">
            <div className="col">
              <span className="material-symbols-outlined icon">edit_note</span>
            </div>
            <div className="col">
              <h2>Want to Book an Event?</h2>
              <p>Contact us for more information.</p>
            </div>
          </div>
          <div className="right">
            <a className="btn" href="/contact">
              Contact
            </a>
            <button onClick={() => handleClose()} className="close">
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InfoBar;
