import React from "react";
import { Banner } from "../../../assets";
import { useNotifications } from "../../../hooks";
import "./container.css";

const Navbar = () => {
  const noti = useNotifications();
  const loc = window.location.pathname;
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  React.useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  return (
    <div className="container navbar">
      <div className="wrapper">
        <div className="left">
          <a
            href="/"
            className={`logo ${loc === "/" || loc === "/home" ? "active" : ""}`}
          >
            <img src={Banner} alt="" />
          </a>
          <a className={loc === "/about" ? "active" : ""} href="/about">
            About & Media
          </a>
          <a
            className={loc === "/reviews" ? "active" : ""}
            href="/reviews"
          >
            Reviews
          </a>
          <a
            className={loc === "/events" ? "active" : ""}
            href="/events"
          >
            Book an Event
          </a>
          <a className={loc === "/contact" ? "active" : ""} href="/contact">
            Contact
          </a>
        </div>
        <div className="right">
          <a
            className="btn"
            href="/order"
          >
            Order Now
          </a>
        </div>
        <button onClick={() => setIsMenuOpen(true)} className="menu">
          <span className="material-symbols-outlined">menu</span>
        </button>
      </div>
      {isMenuOpen && <MobileMenu setIsMenuOpen={setIsMenuOpen} noti={noti} />}
    </div>
  );
};

const MobileMenu = ({ setIsMenuOpen, noti }) => {
  const [isClosing, setIsClosing] = React.useState(false);

  function handleClose() {
    setIsClosing(true);
    setTimeout(() => {
      setIsMenuOpen(false);
      setIsClosing(false);
    }, 300);
  }

  return (
    <div className={`mobilemenu ${isClosing ? 'closing' : ''}`}>
      <div className="wrapper">
        <header>
          <div className="left">
            <a className="logo" href="/">
              <img src={Banner} alt="" />
            </a>
          </div>
          <div className="right">
            <button onClick={handleClose} className="menu">
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        </header>
        <div>
          <a href="/">Home</a>
          <a href="/about">About & Media</a>
          <a href="/reviews">Reviews</a>
          <a href="/events">Book an Event</a>
          <a href="/contact">Contact</a>
          <a href="/order">Order Now</a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
